import ServiceBase from './service-base';
import Cookies from 'js-cookie';

export class AuthService extends ServiceBase {

  async login({ username, password }) {
    return (await this.post('/api/auth/v1/login/user', { username, password })).data;
  }

  async loginAdmin({ username, password }) {
    return (await this.post('/api/auth/v1/login/admin', { username, password })).data;
  }

  async logout() {
    const token = Cookies.get('refreshToken');
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    if (token) {
      return (await this.post('/api/auth/v1/logout', { refreshToken: token })).data;
    }

    return null;
  }

  async refreshToken() {
    const token = Cookies.get('refreshToken');
    if (token) {
      return (await this.post('/api/auth/v1/refreshToken', { refreshToken: token })).data;
    }

    return null;
  }
}
