/* eslint-disable */
// source: main_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var user_proto_pb = require('./user_proto_pb.js');
goog.object.extend(proto, user_proto_pb);
var device_proto_pb = require('./device_proto_pb.js');
goog.object.extend(proto, device_proto_pb);
var common_proto_pb = require('./common_proto_pb.js');
goog.object.extend(proto, common_proto_pb);
goog.exportSymbol('proto.ExceptionMessage', null, global);
goog.exportSymbol('proto.MainMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ExceptionMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ExceptionMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ExceptionMessage.displayName = 'proto.ExceptionMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MainMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MainMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MainMessage.displayName = 'proto.MainMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ExceptionMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ExceptionMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ExceptionMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExceptionMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ExceptionMessage}
 */
proto.ExceptionMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ExceptionMessage;
  return proto.ExceptionMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ExceptionMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ExceptionMessage}
 */
proto.ExceptionMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ExceptionMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ExceptionMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ExceptionMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ExceptionMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1;
 * @return {?proto.StatusCode}
 */
proto.ExceptionMessage.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.ExceptionMessage} returns this
*/
proto.ExceptionMessage.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ExceptionMessage} returns this
 */
proto.ExceptionMessage.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ExceptionMessage.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MainMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.MainMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MainMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MainMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    usermessage: (f = msg.getUsermessage()) && user_proto_pb.UserMessage.toObject(includeInstance, f),
    devicemessage: (f = msg.getDevicemessage()) && device_proto_pb.DeviceMessage.toObject(includeInstance, f),
    exceptionmessage: (f = msg.getExceptionmessage()) && proto.ExceptionMessage.toObject(includeInstance, f),
    requestid: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MainMessage}
 */
proto.MainMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MainMessage;
  return proto.MainMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MainMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MainMessage}
 */
proto.MainMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new user_proto_pb.UserMessage;
      reader.readMessage(value,user_proto_pb.UserMessage.deserializeBinaryFromReader);
      msg.setUsermessage(value);
      break;
    case 2:
      var value = new device_proto_pb.DeviceMessage;
      reader.readMessage(value,device_proto_pb.DeviceMessage.deserializeBinaryFromReader);
      msg.setDevicemessage(value);
      break;
    case 3:
      var value = new proto.ExceptionMessage;
      reader.readMessage(value,proto.ExceptionMessage.deserializeBinaryFromReader);
      msg.setExceptionmessage(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MainMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MainMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MainMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MainMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsermessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      user_proto_pb.UserMessage.serializeBinaryToWriter
    );
  }
  f = message.getDevicemessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      device_proto_pb.DeviceMessage.serializeBinaryToWriter
    );
  }
  f = message.getExceptionmessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ExceptionMessage.serializeBinaryToWriter
    );
  }
  f = message.getRequestid();
  if (f !== 0) {
    writer.writeUint32(
      100,
      f
    );
  }
};


/**
 * optional UserMessage userMessage = 1;
 * @return {?proto.UserMessage}
 */
proto.MainMessage.prototype.getUsermessage = function() {
  return /** @type{?proto.UserMessage} */ (
    jspb.Message.getWrapperField(this, user_proto_pb.UserMessage, 1));
};


/**
 * @param {?proto.UserMessage|undefined} value
 * @return {!proto.MainMessage} returns this
*/
proto.MainMessage.prototype.setUsermessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MainMessage} returns this
 */
proto.MainMessage.prototype.clearUsermessage = function() {
  return this.setUsermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MainMessage.prototype.hasUsermessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceMessage deviceMessage = 2;
 * @return {?proto.DeviceMessage}
 */
proto.MainMessage.prototype.getDevicemessage = function() {
  return /** @type{?proto.DeviceMessage} */ (
    jspb.Message.getWrapperField(this, device_proto_pb.DeviceMessage, 2));
};


/**
 * @param {?proto.DeviceMessage|undefined} value
 * @return {!proto.MainMessage} returns this
*/
proto.MainMessage.prototype.setDevicemessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MainMessage} returns this
 */
proto.MainMessage.prototype.clearDevicemessage = function() {
  return this.setDevicemessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MainMessage.prototype.hasDevicemessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ExceptionMessage exceptionMessage = 3;
 * @return {?proto.ExceptionMessage}
 */
proto.MainMessage.prototype.getExceptionmessage = function() {
  return /** @type{?proto.ExceptionMessage} */ (
    jspb.Message.getWrapperField(this, proto.ExceptionMessage, 3));
};


/**
 * @param {?proto.ExceptionMessage|undefined} value
 * @return {!proto.MainMessage} returns this
*/
proto.MainMessage.prototype.setExceptionmessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MainMessage} returns this
 */
proto.MainMessage.prototype.clearExceptionmessage = function() {
  return this.setExceptionmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MainMessage.prototype.hasExceptionmessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 requestId = 100;
 * @return {number}
 */
proto.MainMessage.prototype.getRequestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.MainMessage} returns this
 */
proto.MainMessage.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


goog.object.extend(exports, proto);
