import { Row, Container, Col, Button, ButtonGroup, ToggleButton, Alert, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import RangeSlider from 'react-bootstrap-range-slider';

function SliderWithInputFormControl({ data, onChange }) {

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (data) setValue(data);
  }, [data])

  return (
    <div style={{
      padding: '1.5em 1.5em 0 1.5em'
    }}>
      <Form style={{
        width: '100%'
      }}>
        <Form.Group as={Row}>
          <Col xs="2">
            <p style={{
              fontSize: '18px',
            }}>Dim:</p>
          </Col>
          <Col xs="10">
            <RangeSlider
              style={{
                width: '15em'
              }}
              size="lg"
              value={value}
              onChange={e => setValue(e.target.value)}
              onMouseDown={() => {
                console.log(`end: ${value}`)
              }}
            />
          </Col>
        </Form.Group>
      </Form>
    </div>
  );

};

export default SliderWithInputFormControl;