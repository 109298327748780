import ServiceBase from './service-base';

export class DeviceService extends ServiceBase {
    baseUri = '';

    async getDeviceById(id) {
        return (await this.get('/api/device/v1/' + id)).data;
    }

    async checkDevice(id) {
        return (await this.post('/api/device/v1/checkDevice', { hardwareId: id })).data;
    }
}
