/* eslint-disable */
// source: device_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_proto_pb = require('./common_proto_pb.js');
goog.object.extend(proto, common_proto_pb);
goog.exportSymbol('proto.AnnounceErrorDeviceRequest', null, global);
goog.exportSymbol('proto.ControlDimAllDeviceRequest', null, global);
goog.exportSymbol('proto.ControlDimAllDeviceResponse', null, global);
goog.exportSymbol('proto.ControlPowerDeviceRequest', null, global);
goog.exportSymbol('proto.ControlPowerDeviceResponse', null, global);
goog.exportSymbol('proto.DeviceAuthMessage', null, global);
goog.exportSymbol('proto.DeviceLogUpdate', null, global);
goog.exportSymbol('proto.DeviceLoginRequest', null, global);
goog.exportSymbol('proto.DeviceLoginResponse', null, global);
goog.exportSymbol('proto.DeviceMessage', null, global);
goog.exportSymbol('proto.DeviceRegisterRequest', null, global);
goog.exportSymbol('proto.DeviceRegisterResponse', null, global);
goog.exportSymbol('proto.DeviceUpdateConfigureResponse', null, global);
goog.exportSymbol('proto.DeviceUpdateMessage', null, global);
goog.exportSymbol('proto.DeviceUpdateScheduleRequest', null, global);
goog.exportSymbol('proto.DeviceUpdateScheduleResponse', null, global);
goog.exportSymbol('proto.PingWebsocketDeviceRequest', null, global);
goog.exportSymbol('proto.PingWebsocketDeviceResponse', null, global);
goog.exportSymbol('proto.RequireStreamDeviceRequest', null, global);
goog.exportSymbol('proto.RequireStreamDeviceResponse', null, global);
goog.exportSymbol('proto.SetActiveTimeDeviceRequest', null, global);
goog.exportSymbol('proto.SetActiveTimeDeviceResponse', null, global);
goog.exportSymbol('proto.StateOnChangeDeviceMessage', null, global);
goog.exportSymbol('proto.UpdateCoordinateDeviceRequest', null, global);
goog.exportSymbol('proto.UpdateCoordinateDeviceResponse', null, global);
goog.exportSymbol('proto.UpdateMeterDataDeviceRequest', null, global);
goog.exportSymbol('proto.UserDeviceMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceRegisterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceRegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceRegisterRequest.displayName = 'proto.DeviceRegisterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateMeterDataDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateMeterDataDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateMeterDataDeviceRequest.displayName = 'proto.UpdateMeterDataDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceRegisterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceRegisterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceRegisterResponse.displayName = 'proto.DeviceRegisterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceLoginRequest.displayName = 'proto.DeviceLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceLoginResponse.displayName = 'proto.DeviceLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceLogUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceLogUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceLogUpdate.displayName = 'proto.DeviceLogUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StateOnChangeDeviceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StateOnChangeDeviceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StateOnChangeDeviceMessage.displayName = 'proto.StateOnChangeDeviceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateConfigureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceUpdateConfigureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateConfigureResponse.displayName = 'proto.DeviceUpdateConfigureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceUpdateScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateScheduleRequest.displayName = 'proto.DeviceUpdateScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceUpdateScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateScheduleResponse.displayName = 'proto.DeviceUpdateScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceAuthMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceAuthMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceAuthMessage.displayName = 'proto.DeviceAuthMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PingWebsocketDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PingWebsocketDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PingWebsocketDeviceRequest.displayName = 'proto.PingWebsocketDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PingWebsocketDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.PingWebsocketDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PingWebsocketDeviceResponse.displayName = 'proto.PingWebsocketDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateCoordinateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateCoordinateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateCoordinateDeviceRequest.displayName = 'proto.UpdateCoordinateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateCoordinateDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UpdateCoordinateDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateCoordinateDeviceResponse.displayName = 'proto.UpdateCoordinateDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AnnounceErrorDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AnnounceErrorDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AnnounceErrorDeviceRequest.displayName = 'proto.AnnounceErrorDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceUpdateMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateMessage.displayName = 'proto.DeviceUpdateMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequireStreamDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequireStreamDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequireStreamDeviceRequest.displayName = 'proto.RequireStreamDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequireStreamDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.RequireStreamDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequireStreamDeviceResponse.displayName = 'proto.RequireStreamDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlPowerDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ControlPowerDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlPowerDeviceRequest.displayName = 'proto.ControlPowerDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlPowerDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.ControlPowerDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlPowerDeviceResponse.displayName = 'proto.ControlPowerDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlDimAllDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ControlDimAllDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlDimAllDeviceRequest.displayName = 'proto.ControlDimAllDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlDimAllDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.ControlDimAllDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlDimAllDeviceResponse.displayName = 'proto.ControlDimAllDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetActiveTimeDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetActiveTimeDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetActiveTimeDeviceRequest.displayName = 'proto.SetActiveTimeDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetActiveTimeDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.SetActiveTimeDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetActiveTimeDeviceResponse.displayName = 'proto.SetActiveTimeDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserDeviceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserDeviceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserDeviceMessage.displayName = 'proto.UserDeviceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceMessage.displayName = 'proto.DeviceMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceRegisterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceRegisterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceRegisterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mantoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicetype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numphase: jspb.Message.getFieldWithDefault(msg, 4, 0),
    firmwareversion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cpuimei: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    gatewayversion: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceRegisterRequest}
 */
proto.DeviceRegisterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceRegisterRequest;
  return proto.DeviceRegisterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceRegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceRegisterRequest}
 */
proto.DeviceRegisterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMantoken(value);
      break;
    case 3:
      var value = /** @type {!proto.DeviceType} */ (reader.readEnum());
      msg.setDevicetype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareversion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpuimei(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceRegisterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceRegisterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceRegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMantoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicetype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getFirmwareversion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCpuimei();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getGatewayversion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string hardwareId = 1;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string manToken = 2;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getMantoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setMantoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceType deviceType = 3;
 * @return {!proto.DeviceType}
 */
proto.DeviceRegisterRequest.prototype.getDevicetype = function() {
  return /** @type {!proto.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.DeviceType} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 numPhase = 4;
 * @return {number}
 */
proto.DeviceRegisterRequest.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string firmwareVersion = 5;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getFirmwareversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setFirmwareversion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cpuIMEI = 6;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getCpuimei = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setCpuimei = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float lat = 7;
 * @return {number}
 */
proto.DeviceRegisterRequest.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float log = 8;
 * @return {number}
 */
proto.DeviceRegisterRequest.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string gatewayVersion = 9;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getGatewayversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setGatewayversion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateMeterDataDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateMeterDataDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateMeterDataDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateMeterDataDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meterdata: (f = msg.getMeterdata()) && common_proto_pb.MeterData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateMeterDataDeviceRequest}
 */
proto.UpdateMeterDataDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateMeterDataDeviceRequest;
  return proto.UpdateMeterDataDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateMeterDataDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateMeterDataDeviceRequest}
 */
proto.UpdateMeterDataDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.MeterData;
      reader.readMessage(value,common_proto_pb.MeterData.deserializeBinaryFromReader);
      msg.setMeterdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateMeterDataDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateMeterDataDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateMeterDataDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateMeterDataDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeterdata();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.MeterData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateMeterDataDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateMeterDataDeviceRequest} returns this
 */
proto.UpdateMeterDataDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MeterData meterData = 2;
 * @return {?proto.MeterData}
 */
proto.UpdateMeterDataDeviceRequest.prototype.getMeterdata = function() {
  return /** @type{?proto.MeterData} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.MeterData, 2));
};


/**
 * @param {?proto.MeterData|undefined} value
 * @return {!proto.UpdateMeterDataDeviceRequest} returns this
*/
proto.UpdateMeterDataDeviceRequest.prototype.setMeterdata = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateMeterDataDeviceRequest} returns this
 */
proto.UpdateMeterDataDeviceRequest.prototype.clearMeterdata = function() {
  return this.setMeterdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateMeterDataDeviceRequest.prototype.hasMeterdata = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceRegisterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceRegisterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceRegisterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceRegisterResponse}
 */
proto.DeviceRegisterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceRegisterResponse;
  return proto.DeviceRegisterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceRegisterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceRegisterResponse}
 */
proto.DeviceRegisterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceRegisterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceRegisterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceRegisterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceRegisterResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterResponse} returns this
 */
proto.DeviceRegisterResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceRegisterResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceRegisterResponse} returns this
*/
proto.DeviceRegisterResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceRegisterResponse} returns this
 */
proto.DeviceRegisterResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceRegisterResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cpuimage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceLoginRequest}
 */
proto.DeviceLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceLoginRequest;
  return proto.DeviceLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceLoginRequest}
 */
proto.DeviceLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpuimage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCpuimage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceLoginRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLoginRequest} returns this
 */
proto.DeviceLoginRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cpuImage = 2;
 * @return {string}
 */
proto.DeviceLoginRequest.prototype.getCpuimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLoginRequest} returns this
 */
proto.DeviceLoginRequest.prototype.setCpuimage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceLoginResponse}
 */
proto.DeviceLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceLoginResponse;
  return proto.DeviceLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceLoginResponse}
 */
proto.DeviceLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceLoginResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceLoginResponse} returns this
*/
proto.DeviceLoginResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceLoginResponse} returns this
 */
proto.DeviceLoginResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceLoginResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceLogUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceLogUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceLogUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLogUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceLogUpdate}
 */
proto.DeviceLogUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceLogUpdate;
  return proto.DeviceLogUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceLogUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceLogUpdate}
 */
proto.DeviceLogUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceLogUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceLogUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceLogUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLogUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceLogUpdate.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLogUpdate} returns this
 */
proto.DeviceLogUpdate.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.DeviceLogUpdate.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLogUpdate} returns this
 */
proto.DeviceLogUpdate.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StateOnChangeDeviceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.StateOnChangeDeviceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StateOnChangeDeviceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeDeviceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicestate: (f = msg.getDevicestate()) && common_proto_pb.DeviceState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StateOnChangeDeviceMessage}
 */
proto.StateOnChangeDeviceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StateOnChangeDeviceMessage;
  return proto.StateOnChangeDeviceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StateOnChangeDeviceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StateOnChangeDeviceMessage}
 */
proto.StateOnChangeDeviceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.DeviceState;
      reader.readMessage(value,common_proto_pb.DeviceState.deserializeBinaryFromReader);
      msg.setDevicestate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StateOnChangeDeviceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StateOnChangeDeviceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StateOnChangeDeviceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeDeviceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicestate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.DeviceState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.StateOnChangeDeviceMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StateOnChangeDeviceMessage} returns this
 */
proto.StateOnChangeDeviceMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceState deviceState = 2;
 * @return {?proto.DeviceState}
 */
proto.StateOnChangeDeviceMessage.prototype.getDevicestate = function() {
  return /** @type{?proto.DeviceState} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DeviceState, 2));
};


/**
 * @param {?proto.DeviceState|undefined} value
 * @return {!proto.StateOnChangeDeviceMessage} returns this
*/
proto.StateOnChangeDeviceMessage.prototype.setDevicestate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StateOnChangeDeviceMessage} returns this
 */
proto.StateOnChangeDeviceMessage.prototype.clearDevicestate = function() {
  return this.setDevicestate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StateOnChangeDeviceMessage.prototype.hasDevicestate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateConfigureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateConfigureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateConfigureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateConfigureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateConfigureResponse}
 */
proto.DeviceUpdateConfigureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateConfigureResponse;
  return proto.DeviceUpdateConfigureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateConfigureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateConfigureResponse}
 */
proto.DeviceUpdateConfigureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateConfigureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateConfigureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateConfigureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateConfigureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceUpdateConfigureResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceUpdateConfigureResponse} returns this
*/
proto.DeviceUpdateConfigureResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateConfigureResponse} returns this
 */
proto.DeviceUpdateConfigureResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateConfigureResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deviceschedule: (f = msg.getDeviceschedule()) && common_proto_pb.DeviceSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateScheduleRequest}
 */
proto.DeviceUpdateScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateScheduleRequest;
  return proto.DeviceUpdateScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateScheduleRequest}
 */
proto.DeviceUpdateScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.DeviceSchedule;
      reader.readMessage(value,common_proto_pb.DeviceSchedule.deserializeBinaryFromReader);
      msg.setDeviceschedule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeviceschedule();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.DeviceSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceUpdateScheduleRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceSchedule deviceSchedule = 2;
 * @return {?proto.DeviceSchedule}
 */
proto.DeviceUpdateScheduleRequest.prototype.getDeviceschedule = function() {
  return /** @type{?proto.DeviceSchedule} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DeviceSchedule, 2));
};


/**
 * @param {?proto.DeviceSchedule|undefined} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
*/
proto.DeviceUpdateScheduleRequest.prototype.setDeviceschedule = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.clearDeviceschedule = function() {
  return this.setDeviceschedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleRequest.prototype.hasDeviceschedule = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateScheduleResponse}
 */
proto.DeviceUpdateScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateScheduleResponse;
  return proto.DeviceUpdateScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateScheduleResponse}
 */
proto.DeviceUpdateScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceUpdateScheduleResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceUpdateScheduleResponse} returns this
*/
proto.DeviceUpdateScheduleResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleResponse} returns this
 */
proto.DeviceUpdateScheduleResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceAuthMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceAuthMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceAuthMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceAuthMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceregisterrequest: (f = msg.getDeviceregisterrequest()) && proto.DeviceRegisterRequest.toObject(includeInstance, f),
    deviceregisterresponse: (f = msg.getDeviceregisterresponse()) && proto.DeviceRegisterResponse.toObject(includeInstance, f),
    deviceloginrequest: (f = msg.getDeviceloginrequest()) && proto.DeviceLoginRequest.toObject(includeInstance, f),
    deviceloginresponse: (f = msg.getDeviceloginresponse()) && proto.DeviceLoginResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceAuthMessage}
 */
proto.DeviceAuthMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceAuthMessage;
  return proto.DeviceAuthMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceAuthMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceAuthMessage}
 */
proto.DeviceAuthMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceRegisterRequest;
      reader.readMessage(value,proto.DeviceRegisterRequest.deserializeBinaryFromReader);
      msg.setDeviceregisterrequest(value);
      break;
    case 2:
      var value = new proto.DeviceRegisterResponse;
      reader.readMessage(value,proto.DeviceRegisterResponse.deserializeBinaryFromReader);
      msg.setDeviceregisterresponse(value);
      break;
    case 3:
      var value = new proto.DeviceLoginRequest;
      reader.readMessage(value,proto.DeviceLoginRequest.deserializeBinaryFromReader);
      msg.setDeviceloginrequest(value);
      break;
    case 4:
      var value = new proto.DeviceLoginResponse;
      reader.readMessage(value,proto.DeviceLoginResponse.deserializeBinaryFromReader);
      msg.setDeviceloginresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceAuthMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceAuthMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceAuthMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceAuthMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceregisterrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceRegisterRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceregisterresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceRegisterResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeviceloginrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeviceLoginRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceloginresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.DeviceLoginResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceRegisterRequest DeviceRegisterRequest = 1;
 * @return {?proto.DeviceRegisterRequest}
 */
proto.DeviceAuthMessage.prototype.getDeviceregisterrequest = function() {
  return /** @type{?proto.DeviceRegisterRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceRegisterRequest, 1));
};


/**
 * @param {?proto.DeviceRegisterRequest|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceregisterrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceregisterrequest = function() {
  return this.setDeviceregisterrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceregisterrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceRegisterResponse deviceRegisterResponse = 2;
 * @return {?proto.DeviceRegisterResponse}
 */
proto.DeviceAuthMessage.prototype.getDeviceregisterresponse = function() {
  return /** @type{?proto.DeviceRegisterResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceRegisterResponse, 2));
};


/**
 * @param {?proto.DeviceRegisterResponse|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceregisterresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceregisterresponse = function() {
  return this.setDeviceregisterresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceregisterresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeviceLoginRequest deviceLoginRequest = 3;
 * @return {?proto.DeviceLoginRequest}
 */
proto.DeviceAuthMessage.prototype.getDeviceloginrequest = function() {
  return /** @type{?proto.DeviceLoginRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceLoginRequest, 3));
};


/**
 * @param {?proto.DeviceLoginRequest|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceloginrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceloginrequest = function() {
  return this.setDeviceloginrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceloginrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DeviceLoginResponse deviceLoginResponse = 4;
 * @return {?proto.DeviceLoginResponse}
 */
proto.DeviceAuthMessage.prototype.getDeviceloginresponse = function() {
  return /** @type{?proto.DeviceLoginResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceLoginResponse, 4));
};


/**
 * @param {?proto.DeviceLoginResponse|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceloginresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceloginresponse = function() {
  return this.setDeviceloginresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceloginresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PingWebsocketDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PingWebsocketDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PingWebsocketDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PingWebsocketDeviceRequest}
 */
proto.PingWebsocketDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PingWebsocketDeviceRequest;
  return proto.PingWebsocketDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PingWebsocketDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PingWebsocketDeviceRequest}
 */
proto.PingWebsocketDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PingWebsocketDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PingWebsocketDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PingWebsocketDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.PingWebsocketDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PingWebsocketDeviceRequest} returns this
 */
proto.PingWebsocketDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PingWebsocketDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.PingWebsocketDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PingWebsocketDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PingWebsocketDeviceResponse}
 */
proto.PingWebsocketDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PingWebsocketDeviceResponse;
  return proto.PingWebsocketDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PingWebsocketDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PingWebsocketDeviceResponse}
 */
proto.PingWebsocketDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PingWebsocketDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PingWebsocketDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PingWebsocketDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.PingWebsocketDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.PingWebsocketDeviceResponse} returns this
*/
proto.PingWebsocketDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PingWebsocketDeviceResponse} returns this
 */
proto.PingWebsocketDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PingWebsocketDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateCoordinateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateCoordinateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateCoordinateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateCoordinateDeviceRequest}
 */
proto.UpdateCoordinateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateCoordinateDeviceRequest;
  return proto.UpdateCoordinateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateCoordinateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateCoordinateDeviceRequest}
 */
proto.UpdateCoordinateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateCoordinateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateCoordinateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateCoordinateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateCoordinateDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateCoordinateDeviceRequest} returns this
 */
proto.UpdateCoordinateDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float lat = 2;
 * @return {number}
 */
proto.UpdateCoordinateDeviceRequest.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateCoordinateDeviceRequest} returns this
 */
proto.UpdateCoordinateDeviceRequest.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float log = 3;
 * @return {number}
 */
proto.UpdateCoordinateDeviceRequest.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateCoordinateDeviceRequest} returns this
 */
proto.UpdateCoordinateDeviceRequest.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateCoordinateDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateCoordinateDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateCoordinateDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateCoordinateDeviceResponse}
 */
proto.UpdateCoordinateDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateCoordinateDeviceResponse;
  return proto.UpdateCoordinateDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateCoordinateDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateCoordinateDeviceResponse}
 */
proto.UpdateCoordinateDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateCoordinateDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateCoordinateDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateCoordinateDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UpdateCoordinateDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UpdateCoordinateDeviceResponse} returns this
*/
proto.UpdateCoordinateDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateCoordinateDeviceResponse} returns this
 */
proto.UpdateCoordinateDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateCoordinateDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AnnounceErrorDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AnnounceErrorDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AnnounceErrorDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AnnounceErrorDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AnnounceErrorDeviceRequest}
 */
proto.AnnounceErrorDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AnnounceErrorDeviceRequest;
  return proto.AnnounceErrorDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AnnounceErrorDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AnnounceErrorDeviceRequest}
 */
proto.AnnounceErrorDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AnnounceErrorDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AnnounceErrorDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AnnounceErrorDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AnnounceErrorDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicelogupdate: (f = msg.getDevicelogupdate()) && proto.DeviceLogUpdate.toObject(includeInstance, f),
    stateonchange: (f = msg.getStateonchange()) && proto.StateOnChangeDeviceMessage.toObject(includeInstance, f),
    pingwebsocketdevicerequest: (f = msg.getPingwebsocketdevicerequest()) && proto.PingWebsocketDeviceRequest.toObject(includeInstance, f),
    pingwebsocketdeviceresponse: (f = msg.getPingwebsocketdeviceresponse()) && proto.PingWebsocketDeviceResponse.toObject(includeInstance, f),
    deviceupdateschedulerequest: (f = msg.getDeviceupdateschedulerequest()) && proto.DeviceUpdateScheduleRequest.toObject(includeInstance, f),
    deviceupdatescheduleresponse: (f = msg.getDeviceupdatescheduleresponse()) && proto.DeviceUpdateScheduleResponse.toObject(includeInstance, f),
    updatecoordinatedevicerequest: (f = msg.getUpdatecoordinatedevicerequest()) && proto.UpdateCoordinateDeviceRequest.toObject(includeInstance, f),
    updatecoordinatedeviceresponse: (f = msg.getUpdatecoordinatedeviceresponse()) && proto.UpdateCoordinateDeviceResponse.toObject(includeInstance, f),
    announceerrordevicerequest: (f = msg.getAnnounceerrordevicerequest()) && proto.AnnounceErrorDeviceRequest.toObject(includeInstance, f),
    updatemeterdatadevicerequest: (f = msg.getUpdatemeterdatadevicerequest()) && proto.UpdateMeterDataDeviceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateMessage}
 */
proto.DeviceUpdateMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateMessage;
  return proto.DeviceUpdateMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateMessage}
 */
proto.DeviceUpdateMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceLogUpdate;
      reader.readMessage(value,proto.DeviceLogUpdate.deserializeBinaryFromReader);
      msg.setDevicelogupdate(value);
      break;
    case 2:
      var value = new proto.StateOnChangeDeviceMessage;
      reader.readMessage(value,proto.StateOnChangeDeviceMessage.deserializeBinaryFromReader);
      msg.setStateonchange(value);
      break;
    case 5:
      var value = new proto.PingWebsocketDeviceRequest;
      reader.readMessage(value,proto.PingWebsocketDeviceRequest.deserializeBinaryFromReader);
      msg.setPingwebsocketdevicerequest(value);
      break;
    case 6:
      var value = new proto.PingWebsocketDeviceResponse;
      reader.readMessage(value,proto.PingWebsocketDeviceResponse.deserializeBinaryFromReader);
      msg.setPingwebsocketdeviceresponse(value);
      break;
    case 7:
      var value = new proto.DeviceUpdateScheduleRequest;
      reader.readMessage(value,proto.DeviceUpdateScheduleRequest.deserializeBinaryFromReader);
      msg.setDeviceupdateschedulerequest(value);
      break;
    case 8:
      var value = new proto.DeviceUpdateScheduleResponse;
      reader.readMessage(value,proto.DeviceUpdateScheduleResponse.deserializeBinaryFromReader);
      msg.setDeviceupdatescheduleresponse(value);
      break;
    case 9:
      var value = new proto.UpdateCoordinateDeviceRequest;
      reader.readMessage(value,proto.UpdateCoordinateDeviceRequest.deserializeBinaryFromReader);
      msg.setUpdatecoordinatedevicerequest(value);
      break;
    case 10:
      var value = new proto.UpdateCoordinateDeviceResponse;
      reader.readMessage(value,proto.UpdateCoordinateDeviceResponse.deserializeBinaryFromReader);
      msg.setUpdatecoordinatedeviceresponse(value);
      break;
    case 11:
      var value = new proto.AnnounceErrorDeviceRequest;
      reader.readMessage(value,proto.AnnounceErrorDeviceRequest.deserializeBinaryFromReader);
      msg.setAnnounceerrordevicerequest(value);
      break;
    case 12:
      var value = new proto.UpdateMeterDataDeviceRequest;
      reader.readMessage(value,proto.UpdateMeterDataDeviceRequest.deserializeBinaryFromReader);
      msg.setUpdatemeterdatadevicerequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicelogupdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceLogUpdate.serializeBinaryToWriter
    );
  }
  f = message.getStateonchange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.StateOnChangeDeviceMessage.serializeBinaryToWriter
    );
  }
  f = message.getPingwebsocketdevicerequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.PingWebsocketDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getPingwebsocketdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.PingWebsocketDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdateschedulerequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.DeviceUpdateScheduleRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdatescheduleresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.DeviceUpdateScheduleResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatecoordinatedevicerequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.UpdateCoordinateDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatecoordinatedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.UpdateCoordinateDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getAnnounceerrordevicerequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.AnnounceErrorDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemeterdatadevicerequest();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.UpdateMeterDataDeviceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceLogUpdate deviceLogUpdate = 1;
 * @return {?proto.DeviceLogUpdate}
 */
proto.DeviceUpdateMessage.prototype.getDevicelogupdate = function() {
  return /** @type{?proto.DeviceLogUpdate} */ (
    jspb.Message.getWrapperField(this, proto.DeviceLogUpdate, 1));
};


/**
 * @param {?proto.DeviceLogUpdate|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDevicelogupdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDevicelogupdate = function() {
  return this.setDevicelogupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDevicelogupdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateOnChangeDeviceMessage stateOnChange = 2;
 * @return {?proto.StateOnChangeDeviceMessage}
 */
proto.DeviceUpdateMessage.prototype.getStateonchange = function() {
  return /** @type{?proto.StateOnChangeDeviceMessage} */ (
    jspb.Message.getWrapperField(this, proto.StateOnChangeDeviceMessage, 2));
};


/**
 * @param {?proto.StateOnChangeDeviceMessage|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setStateonchange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearStateonchange = function() {
  return this.setStateonchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasStateonchange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PingWebsocketDeviceRequest pingWebsocketDeviceRequest = 5;
 * @return {?proto.PingWebsocketDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getPingwebsocketdevicerequest = function() {
  return /** @type{?proto.PingWebsocketDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.PingWebsocketDeviceRequest, 5));
};


/**
 * @param {?proto.PingWebsocketDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setPingwebsocketdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearPingwebsocketdevicerequest = function() {
  return this.setPingwebsocketdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasPingwebsocketdevicerequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PingWebsocketDeviceResponse pingWebsocketDeviceResponse = 6;
 * @return {?proto.PingWebsocketDeviceResponse}
 */
proto.DeviceUpdateMessage.prototype.getPingwebsocketdeviceresponse = function() {
  return /** @type{?proto.PingWebsocketDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.PingWebsocketDeviceResponse, 6));
};


/**
 * @param {?proto.PingWebsocketDeviceResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setPingwebsocketdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearPingwebsocketdeviceresponse = function() {
  return this.setPingwebsocketdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasPingwebsocketdeviceresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DeviceUpdateScheduleRequest deviceUpdateScheduleRequest = 7;
 * @return {?proto.DeviceUpdateScheduleRequest}
 */
proto.DeviceUpdateMessage.prototype.getDeviceupdateschedulerequest = function() {
  return /** @type{?proto.DeviceUpdateScheduleRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateScheduleRequest, 7));
};


/**
 * @param {?proto.DeviceUpdateScheduleRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDeviceupdateschedulerequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDeviceupdateschedulerequest = function() {
  return this.setDeviceupdateschedulerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDeviceupdateschedulerequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DeviceUpdateScheduleResponse deviceUpdateScheduleResponse = 8;
 * @return {?proto.DeviceUpdateScheduleResponse}
 */
proto.DeviceUpdateMessage.prototype.getDeviceupdatescheduleresponse = function() {
  return /** @type{?proto.DeviceUpdateScheduleResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateScheduleResponse, 8));
};


/**
 * @param {?proto.DeviceUpdateScheduleResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDeviceupdatescheduleresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDeviceupdatescheduleresponse = function() {
  return this.setDeviceupdatescheduleresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDeviceupdatescheduleresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UpdateCoordinateDeviceRequest updateCoordinateDeviceRequest = 9;
 * @return {?proto.UpdateCoordinateDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getUpdatecoordinatedevicerequest = function() {
  return /** @type{?proto.UpdateCoordinateDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateCoordinateDeviceRequest, 9));
};


/**
 * @param {?proto.UpdateCoordinateDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setUpdatecoordinatedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearUpdatecoordinatedevicerequest = function() {
  return this.setUpdatecoordinatedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasUpdatecoordinatedevicerequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UpdateCoordinateDeviceResponse updateCoordinateDeviceResponse = 10;
 * @return {?proto.UpdateCoordinateDeviceResponse}
 */
proto.DeviceUpdateMessage.prototype.getUpdatecoordinatedeviceresponse = function() {
  return /** @type{?proto.UpdateCoordinateDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.UpdateCoordinateDeviceResponse, 10));
};


/**
 * @param {?proto.UpdateCoordinateDeviceResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setUpdatecoordinatedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearUpdatecoordinatedeviceresponse = function() {
  return this.setUpdatecoordinatedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasUpdatecoordinatedeviceresponse = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AnnounceErrorDeviceRequest announceErrorDeviceRequest = 11;
 * @return {?proto.AnnounceErrorDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getAnnounceerrordevicerequest = function() {
  return /** @type{?proto.AnnounceErrorDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.AnnounceErrorDeviceRequest, 11));
};


/**
 * @param {?proto.AnnounceErrorDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setAnnounceerrordevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearAnnounceerrordevicerequest = function() {
  return this.setAnnounceerrordevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasAnnounceerrordevicerequest = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UpdateMeterDataDeviceRequest updateMeterDataDeviceRequest = 12;
 * @return {?proto.UpdateMeterDataDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getUpdatemeterdatadevicerequest = function() {
  return /** @type{?proto.UpdateMeterDataDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateMeterDataDeviceRequest, 12));
};


/**
 * @param {?proto.UpdateMeterDataDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setUpdatemeterdatadevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearUpdatemeterdatadevicerequest = function() {
  return this.setUpdatemeterdatadevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasUpdatemeterdatadevicerequest = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequireStreamDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RequireStreamDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequireStreamDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequireStreamDeviceRequest}
 */
proto.RequireStreamDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequireStreamDeviceRequest;
  return proto.RequireStreamDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequireStreamDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequireStreamDeviceRequest}
 */
proto.RequireStreamDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequireStreamDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequireStreamDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequireStreamDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.RequireStreamDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequireStreamDeviceRequest} returns this
 */
proto.RequireStreamDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable = 2;
 * @return {boolean}
 */
proto.RequireStreamDeviceRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RequireStreamDeviceRequest} returns this
 */
proto.RequireStreamDeviceRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequireStreamDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RequireStreamDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequireStreamDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequireStreamDeviceResponse}
 */
proto.RequireStreamDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequireStreamDeviceResponse;
  return proto.RequireStreamDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequireStreamDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequireStreamDeviceResponse}
 */
proto.RequireStreamDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequireStreamDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequireStreamDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequireStreamDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.RequireStreamDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.RequireStreamDeviceResponse} returns this
*/
proto.RequireStreamDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequireStreamDeviceResponse} returns this
 */
proto.RequireStreamDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequireStreamDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlPowerDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlPowerDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlPowerDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlPowerDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    powerstate: (f = msg.getPowerstate()) && common_proto_pb.PowerState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlPowerDeviceRequest}
 */
proto.ControlPowerDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlPowerDeviceRequest;
  return proto.ControlPowerDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlPowerDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlPowerDeviceRequest}
 */
proto.ControlPowerDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.PowerState;
      reader.readMessage(value,common_proto_pb.PowerState.deserializeBinaryFromReader);
      msg.setPowerstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlPowerDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlPowerDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlPowerDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlPowerDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPowerstate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.PowerState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ControlPowerDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ControlPowerDeviceRequest} returns this
 */
proto.ControlPowerDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PowerState powerState = 2;
 * @return {?proto.PowerState}
 */
proto.ControlPowerDeviceRequest.prototype.getPowerstate = function() {
  return /** @type{?proto.PowerState} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.PowerState, 2));
};


/**
 * @param {?proto.PowerState|undefined} value
 * @return {!proto.ControlPowerDeviceRequest} returns this
*/
proto.ControlPowerDeviceRequest.prototype.setPowerstate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ControlPowerDeviceRequest} returns this
 */
proto.ControlPowerDeviceRequest.prototype.clearPowerstate = function() {
  return this.setPowerstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ControlPowerDeviceRequest.prototype.hasPowerstate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlPowerDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlPowerDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlPowerDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlPowerDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlPowerDeviceResponse}
 */
proto.ControlPowerDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlPowerDeviceResponse;
  return proto.ControlPowerDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlPowerDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlPowerDeviceResponse}
 */
proto.ControlPowerDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlPowerDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlPowerDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlPowerDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlPowerDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.ControlPowerDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.ControlPowerDeviceResponse} returns this
*/
proto.ControlPowerDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ControlPowerDeviceResponse} returns this
 */
proto.ControlPowerDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ControlPowerDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlDimAllDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlDimAllDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlDimAllDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlDimAllDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dim: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlDimAllDeviceRequest}
 */
proto.ControlDimAllDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlDimAllDeviceRequest;
  return proto.ControlDimAllDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlDimAllDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlDimAllDeviceRequest}
 */
proto.ControlDimAllDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlDimAllDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlDimAllDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlDimAllDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlDimAllDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDim();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ControlDimAllDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ControlDimAllDeviceRequest} returns this
 */
proto.ControlDimAllDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 dim = 3;
 * @return {number}
 */
proto.ControlDimAllDeviceRequest.prototype.getDim = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ControlDimAllDeviceRequest} returns this
 */
proto.ControlDimAllDeviceRequest.prototype.setDim = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlDimAllDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlDimAllDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlDimAllDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlDimAllDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlDimAllDeviceResponse}
 */
proto.ControlDimAllDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlDimAllDeviceResponse;
  return proto.ControlDimAllDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlDimAllDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlDimAllDeviceResponse}
 */
proto.ControlDimAllDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlDimAllDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlDimAllDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlDimAllDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlDimAllDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ControlDimAllDeviceResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ControlDimAllDeviceResponse} returns this
 */
proto.ControlDimAllDeviceResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.ControlDimAllDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.ControlDimAllDeviceResponse} returns this
*/
proto.ControlDimAllDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ControlDimAllDeviceResponse} returns this
 */
proto.ControlDimAllDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ControlDimAllDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetActiveTimeDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetActiveTimeDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetActiveTimeDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activelamp: (f = msg.getActivelamp()) && common_proto_pb.DeviceSchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetActiveTimeDeviceRequest}
 */
proto.SetActiveTimeDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetActiveTimeDeviceRequest;
  return proto.SetActiveTimeDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetActiveTimeDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetActiveTimeDeviceRequest}
 */
proto.SetActiveTimeDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.DeviceSchedule;
      reader.readMessage(value,common_proto_pb.DeviceSchedule.deserializeBinaryFromReader);
      msg.setActivelamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetActiveTimeDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetActiveTimeDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetActiveTimeDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivelamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.DeviceSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SetActiveTimeDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetActiveTimeDeviceRequest} returns this
 */
proto.SetActiveTimeDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceSchedule activeLamp = 2;
 * @return {?proto.DeviceSchedule}
 */
proto.SetActiveTimeDeviceRequest.prototype.getActivelamp = function() {
  return /** @type{?proto.DeviceSchedule} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DeviceSchedule, 2));
};


/**
 * @param {?proto.DeviceSchedule|undefined} value
 * @return {!proto.SetActiveTimeDeviceRequest} returns this
*/
proto.SetActiveTimeDeviceRequest.prototype.setActivelamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetActiveTimeDeviceRequest} returns this
 */
proto.SetActiveTimeDeviceRequest.prototype.clearActivelamp = function() {
  return this.setActivelamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetActiveTimeDeviceRequest.prototype.hasActivelamp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetActiveTimeDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SetActiveTimeDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetActiveTimeDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetActiveTimeDeviceResponse}
 */
proto.SetActiveTimeDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetActiveTimeDeviceResponse;
  return proto.SetActiveTimeDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetActiveTimeDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetActiveTimeDeviceResponse}
 */
proto.SetActiveTimeDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetActiveTimeDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetActiveTimeDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetActiveTimeDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.SetActiveTimeDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.SetActiveTimeDeviceResponse} returns this
*/
proto.SetActiveTimeDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetActiveTimeDeviceResponse} returns this
 */
proto.SetActiveTimeDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetActiveTimeDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserDeviceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UserDeviceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserDeviceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDeviceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    requirestreamdevicerequest: (f = msg.getRequirestreamdevicerequest()) && proto.RequireStreamDeviceRequest.toObject(includeInstance, f),
    requirestreamdeviceresponse: (f = msg.getRequirestreamdeviceresponse()) && proto.RequireStreamDeviceResponse.toObject(includeInstance, f),
    controlpowerdevicerequest: (f = msg.getControlpowerdevicerequest()) && proto.ControlPowerDeviceRequest.toObject(includeInstance, f),
    controlpowerdeviceresponse: (f = msg.getControlpowerdeviceresponse()) && proto.ControlPowerDeviceResponse.toObject(includeInstance, f),
    controldimalldevicerequest: (f = msg.getControldimalldevicerequest()) && proto.ControlDimAllDeviceRequest.toObject(includeInstance, f),
    controldimalldeviceresponse: (f = msg.getControldimalldeviceresponse()) && proto.ControlDimAllDeviceResponse.toObject(includeInstance, f),
    setactivetimedevicerequest: (f = msg.getSetactivetimedevicerequest()) && proto.SetActiveTimeDeviceRequest.toObject(includeInstance, f),
    setactivetimedeviceresponse: (f = msg.getSetactivetimedeviceresponse()) && proto.SetActiveTimeDeviceResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserDeviceMessage}
 */
proto.UserDeviceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserDeviceMessage;
  return proto.UserDeviceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserDeviceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserDeviceMessage}
 */
proto.UserDeviceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequireStreamDeviceRequest;
      reader.readMessage(value,proto.RequireStreamDeviceRequest.deserializeBinaryFromReader);
      msg.setRequirestreamdevicerequest(value);
      break;
    case 2:
      var value = new proto.RequireStreamDeviceResponse;
      reader.readMessage(value,proto.RequireStreamDeviceResponse.deserializeBinaryFromReader);
      msg.setRequirestreamdeviceresponse(value);
      break;
    case 3:
      var value = new proto.ControlPowerDeviceRequest;
      reader.readMessage(value,proto.ControlPowerDeviceRequest.deserializeBinaryFromReader);
      msg.setControlpowerdevicerequest(value);
      break;
    case 4:
      var value = new proto.ControlPowerDeviceResponse;
      reader.readMessage(value,proto.ControlPowerDeviceResponse.deserializeBinaryFromReader);
      msg.setControlpowerdeviceresponse(value);
      break;
    case 5:
      var value = new proto.ControlDimAllDeviceRequest;
      reader.readMessage(value,proto.ControlDimAllDeviceRequest.deserializeBinaryFromReader);
      msg.setControldimalldevicerequest(value);
      break;
    case 6:
      var value = new proto.ControlDimAllDeviceResponse;
      reader.readMessage(value,proto.ControlDimAllDeviceResponse.deserializeBinaryFromReader);
      msg.setControldimalldeviceresponse(value);
      break;
    case 7:
      var value = new proto.SetActiveTimeDeviceRequest;
      reader.readMessage(value,proto.SetActiveTimeDeviceRequest.deserializeBinaryFromReader);
      msg.setSetactivetimedevicerequest(value);
      break;
    case 8:
      var value = new proto.SetActiveTimeDeviceResponse;
      reader.readMessage(value,proto.SetActiveTimeDeviceResponse.deserializeBinaryFromReader);
      msg.setSetactivetimedeviceresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserDeviceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserDeviceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserDeviceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDeviceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequirestreamdevicerequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequireStreamDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getRequirestreamdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RequireStreamDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getControlpowerdevicerequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ControlPowerDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getControlpowerdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ControlPowerDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getControldimalldevicerequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ControlDimAllDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getControldimalldeviceresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ControlDimAllDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetactivetimedevicerequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.SetActiveTimeDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetactivetimedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.SetActiveTimeDeviceResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequireStreamDeviceRequest requireStreamDeviceRequest = 1;
 * @return {?proto.RequireStreamDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getRequirestreamdevicerequest = function() {
  return /** @type{?proto.RequireStreamDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.RequireStreamDeviceRequest, 1));
};


/**
 * @param {?proto.RequireStreamDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setRequirestreamdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearRequirestreamdevicerequest = function() {
  return this.setRequirestreamdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasRequirestreamdevicerequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequireStreamDeviceResponse requireStreamDeviceResponse = 2;
 * @return {?proto.RequireStreamDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getRequirestreamdeviceresponse = function() {
  return /** @type{?proto.RequireStreamDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.RequireStreamDeviceResponse, 2));
};


/**
 * @param {?proto.RequireStreamDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setRequirestreamdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearRequirestreamdeviceresponse = function() {
  return this.setRequirestreamdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasRequirestreamdeviceresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ControlPowerDeviceRequest controlPowerDeviceRequest = 3;
 * @return {?proto.ControlPowerDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getControlpowerdevicerequest = function() {
  return /** @type{?proto.ControlPowerDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.ControlPowerDeviceRequest, 3));
};


/**
 * @param {?proto.ControlPowerDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControlpowerdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControlpowerdevicerequest = function() {
  return this.setControlpowerdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControlpowerdevicerequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ControlPowerDeviceResponse controlPowerDeviceResponse = 4;
 * @return {?proto.ControlPowerDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getControlpowerdeviceresponse = function() {
  return /** @type{?proto.ControlPowerDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.ControlPowerDeviceResponse, 4));
};


/**
 * @param {?proto.ControlPowerDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControlpowerdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControlpowerdeviceresponse = function() {
  return this.setControlpowerdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControlpowerdeviceresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ControlDimAllDeviceRequest controlDimAllDeviceRequest = 5;
 * @return {?proto.ControlDimAllDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getControldimalldevicerequest = function() {
  return /** @type{?proto.ControlDimAllDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.ControlDimAllDeviceRequest, 5));
};


/**
 * @param {?proto.ControlDimAllDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControldimalldevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControldimalldevicerequest = function() {
  return this.setControldimalldevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControldimalldevicerequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ControlDimAllDeviceResponse controlDimAllDeviceResponse = 6;
 * @return {?proto.ControlDimAllDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getControldimalldeviceresponse = function() {
  return /** @type{?proto.ControlDimAllDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.ControlDimAllDeviceResponse, 6));
};


/**
 * @param {?proto.ControlDimAllDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControldimalldeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControldimalldeviceresponse = function() {
  return this.setControldimalldeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControldimalldeviceresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SetActiveTimeDeviceRequest setActiveTimeDeviceRequest = 7;
 * @return {?proto.SetActiveTimeDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getSetactivetimedevicerequest = function() {
  return /** @type{?proto.SetActiveTimeDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetActiveTimeDeviceRequest, 7));
};


/**
 * @param {?proto.SetActiveTimeDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSetactivetimedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSetactivetimedevicerequest = function() {
  return this.setSetactivetimedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSetactivetimedevicerequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SetActiveTimeDeviceResponse setActiveTimeDeviceResponse = 8;
 * @return {?proto.SetActiveTimeDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getSetactivetimedeviceresponse = function() {
  return /** @type{?proto.SetActiveTimeDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.SetActiveTimeDeviceResponse, 8));
};


/**
 * @param {?proto.SetActiveTimeDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSetactivetimedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSetactivetimedeviceresponse = function() {
  return this.setSetactivetimedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSetactivetimedeviceresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceauthmessage: (f = msg.getDeviceauthmessage()) && proto.DeviceAuthMessage.toObject(includeInstance, f),
    deviceupdatemessage: (f = msg.getDeviceupdatemessage()) && proto.DeviceUpdateMessage.toObject(includeInstance, f),
    userdevicemessage: (f = msg.getUserdevicemessage()) && proto.UserDeviceMessage.toObject(includeInstance, f),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceMessage}
 */
proto.DeviceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceMessage;
  return proto.DeviceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceMessage}
 */
proto.DeviceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceAuthMessage;
      reader.readMessage(value,proto.DeviceAuthMessage.deserializeBinaryFromReader);
      msg.setDeviceauthmessage(value);
      break;
    case 2:
      var value = new proto.DeviceUpdateMessage;
      reader.readMessage(value,proto.DeviceUpdateMessage.deserializeBinaryFromReader);
      msg.setDeviceupdatemessage(value);
      break;
    case 3:
      var value = new proto.UserDeviceMessage;
      reader.readMessage(value,proto.UserDeviceMessage.deserializeBinaryFromReader);
      msg.setUserdevicemessage(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceauthmessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceAuthMessage.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdatemessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceUpdateMessage.serializeBinaryToWriter
    );
  }
  f = message.getUserdevicemessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.UserDeviceMessage.serializeBinaryToWriter
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional DeviceAuthMessage deviceAuthMessage = 1;
 * @return {?proto.DeviceAuthMessage}
 */
proto.DeviceMessage.prototype.getDeviceauthmessage = function() {
  return /** @type{?proto.DeviceAuthMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceAuthMessage, 1));
};


/**
 * @param {?proto.DeviceAuthMessage|undefined} value
 * @return {!proto.DeviceMessage} returns this
*/
proto.DeviceMessage.prototype.setDeviceauthmessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.clearDeviceauthmessage = function() {
  return this.setDeviceauthmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceMessage.prototype.hasDeviceauthmessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceUpdateMessage deviceUpdateMessage = 2;
 * @return {?proto.DeviceUpdateMessage}
 */
proto.DeviceMessage.prototype.getDeviceupdatemessage = function() {
  return /** @type{?proto.DeviceUpdateMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateMessage, 2));
};


/**
 * @param {?proto.DeviceUpdateMessage|undefined} value
 * @return {!proto.DeviceMessage} returns this
*/
proto.DeviceMessage.prototype.setDeviceupdatemessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.clearDeviceupdatemessage = function() {
  return this.setDeviceupdatemessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceMessage.prototype.hasDeviceupdatemessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserDeviceMessage userDeviceMessage = 3;
 * @return {?proto.UserDeviceMessage}
 */
proto.DeviceMessage.prototype.getUserdevicemessage = function() {
  return /** @type{?proto.UserDeviceMessage} */ (
    jspb.Message.getWrapperField(this, proto.UserDeviceMessage, 3));
};


/**
 * @param {?proto.UserDeviceMessage|undefined} value
 * @return {!proto.DeviceMessage} returns this
*/
proto.DeviceMessage.prototype.setUserdevicemessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.clearUserdevicemessage = function() {
  return this.setUserdevicemessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceMessage.prototype.hasUserdevicemessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string hardwareId = 100;
 * @return {string}
 */
proto.DeviceMessage.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


goog.object.extend(exports, proto);
