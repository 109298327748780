import { useState, useEffect } from "react";
import { Form, Col, Row, Container, Button } from "react-bootstrap";
import RangeSlider from 'react-bootstrap-range-slider';
import { StationService } from "../../services/StationService";

async function updateOutput(stationId, channel1, channel2, channel3, dim) {
  if (!stationId) {
    return;
  }
  let stationService = new StationService();
  let response = await stationService.dimControl(stationId, dim);
  if (response && response.code !== 0) {
    alert(response.message);
  }
}

function EnableOutput({stationId, channel1, channel2, channel3, dim, disableDim, onChange}) {
  const [value1, setValue1] = useState(true);
  const [value2, setValue2] = useState(true);
  const [value3, setValue3] = useState(true);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (channel1 !== undefined) setValue1(channel1);
    if (channel2 !== undefined) setValue2(channel2);
    if (channel3 !== undefined) setValue3(channel3);
    // if (dim) setValue(dim);
  }, [channel1, channel2, channel3, dim]);

  return (
    <div style={{padding: '1.5em 0 0 0'}}>
      <Row>
        <Col>
          <Form style={{
            width: '100%'
          }}>
            <Form.Group as={Row}>
              <Col xs="5">
                <p style={{
                  fontSize: '18px',
                }}>Ngõ ra sử dụng:</p>
              </Col>
              <Col xs="2" style={{padding: 0, margin: 0}}>
                <input disabled={true} type="checkbox" checked={value1} onChange={(e) => setValue1(e.target.value)} /> 1
              </Col>
              <Col xs="2">
                <input disabled={true} type="checkbox" checked={value2} onChange={(e) => setValue2(e.target.value)} /> 2
              </Col>
              <Col xs="2">
                <input disabled={true} type="checkbox" checked={value3} onChange={(e) => setValue3(e.target.value)} /> 3
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form style={{
            width: '100%'
          }}>
            <Form.Group as={Row}>
              <Col xs="2">
                <p style={{
                  fontSize: '18px',
                }}>Dim:</p>
              </Col>
              <Col xs="10">
                <RangeSlider
                  style={{
                    width: '15em'
                  }}
                  disabled={disableDim}
                  size="lg"
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  onMouseDown={() => {
                    console.log(`end: ${value}`)
                  }}
                />
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => {
            // if (onSubmitSetup) {
              updateOutput(stationId, value1, value2, value3, value);
            // }
          }}>
            Thiết lập
          </Button>
        </Col>
      </Row>
    </div>
  )

}

export default EnableOutput;