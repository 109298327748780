import ServiceBase from './service-base';

export class UserService extends ServiceBase {
  baseUri = '';

  async getListUser() {
    return (await this.get('/api/user/v1/getList')).data;
  }

  async getUserInfo() {
    return (await this.get('/api/user/v1/getInfo')).data;
  }

  async createUser({ name, email, username, password, phone, role }) {
    return (await this.post('/api/user/v1/create', { name, email, username, password, phone, role })).data;
  }

  async updateUser({ name, email, phone }) {
    return (await this.put('/api/user/v1/update', { name, email, phone })).data;
  }

  async updatePassword({ currentPassword, newPassword }) {
    return (await this.post('/api/user/v1/updatePassword', { currentPassword, newPassword })).data;
  }

  async deleteUser(id) {
    return (await this.delete('/api/user/v1/delete/' + id)).data;
  }

  async updateAvatar(url) {
    const urlImage = url.startsWith('http') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`;

    return (await this.post('/api/user/v1/updateAvatar', { url: urlImage })).data;
  }
}
