import { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import { BiTime, BiChip, BiTimer, BiArrowBack, BiRightArrowAlt } from 'react-icons/bi';
import {ScheduleService} from "../../services/ScheduleService";

async function updateSchedule(stationId, activeSeason, fromTimeSeason, toTimeSeason, index, fromTimer, toTimer, channel1, channel2, channel3) {
  let scheduleService = new ScheduleService();
  let response = await scheduleService.updateSchedule({
    stationId: stationId,
    useSunshine: activeSeason,
    sunshineActive: {
        start: fromTimeSeason,
        end: toTimeSeason,
        useOffset: true
    },
    index: index,
    timerActive: {
        start: fromTimer,
        end: toTimer,
        channel1: channel1,
        channel2: channel2,
        channel3: channel3
    }
  });
  console.log(`update schedule: ${response}`);
  if (response && response.code !== 0) {
    alert(response.message);
  }
}

function SeasonSetup({ stationId, _activeSeason, _fromTimeSeason, _toTimeSeason, _timers, onSubmitSetup }) {
  const [activeSeason, setActiveSeason] = useState(false);
  const [fromTimeSeason, setFromTimeSeason] = useState('00:00');
  const [toTimeSeason, setToTimeSeason] = useState('23:00');
  const [fromTimer, setFromTimer] = useState('00:00');
  const [toTimer, setToTimer] = useState('23:00');
  const [timers, setTimers] = useState([]);
  const [index, setIndex] = useState(0);
  const [value1, setValue1] = useState(true);
  const [value2, setValue2] = useState(true);
  const [value3, setValue3] = useState(true);

  useEffect(() => {
    if (_activeSeason !== undefined) setActiveSeason(_activeSeason);
    if (_fromTimeSeason) setFromTimeSeason(_fromTimeSeason);
    if (_toTimeSeason) setToTimeSeason(_toTimeSeason);
    if (_timers) setTimers(_timers);
    if (_timers && _timers.length > 0) {
      setIndex(0);
      setFromTimer(_timers[0]?.start)
      setToTimer(_timers[0]?.end)
      setValue1(_timers[0]?.channel1)
      setValue2(_timers[0]?.channel2)
      setValue3(_timers[0]?.channel3)
    }
  }, [_activeSeason, _fromTimeSeason, _toTimeSeason, _timers]);

  return (
    <div>
      <Form style={{
        width: '100%',
        padding: 0,
        margin: 0
      }}>
        <Form.Group as={Row} style={{
          margin: '0px'
        }}>
          <Col xs="6">
            <p style={{
              fontSize: '16px',
            }}>Hoạt động theo mùa:</p>
          </Col>
          <Col xs="2" style={{ padding: 0, margin: 0 }}>
            <input type="checkbox" checked={activeSeason} onChange={(e) => setActiveSeason(e.target.checked)} />
          </Col>
        </Form.Group>
        <Row>
          <Col>
            Từ: <input
              className='monitor-input-time-active'
              style={{ textAlign: 'center' }}
              type="time"
              id="fname"
              name="fname"
              value={fromTimeSeason}
              onChange={(event) => setFromTimeSeason(event.target.value)}
            // disabled={true}
            />
          </Col>
          <Col xs={1}><BiRightArrowAlt size={25} /></Col>
          <Col>
            Đến: <input
              className='monitor-input-time-active'
              style={{ textAlign: 'center' }}
              type="time"
              id="fname"
              name="fname"
              value={toTimeSeason}
              onChange={(event) => setToTimeSeason(event.target.value)}
            // disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '10px', borderBottom: '1px dotted gray', margin: '1em' }}></Col>
        </Row>
        <Row style={{padding: '0.5em'}}>
          <Col>
            Hoạt động theo timer:
          </Col>
          <Col>
            <select
              value={index}
              onChange={(event) => {
                let i = event.target.value;
                setIndex(i);
                if (timers.length > i) {
                  setFromTimer(timers[i].start)
                  setToTimer(timers[i].end)
                  setValue1(timers[i].channel1)
                  setValue2(timers[i].channel2)
                  setValue3(timers[i].channel3)
                }
              }}
            >
              {
                Array(48).fill(0).map((_, i) => (
                  <option value={i}>Timer {i + 1}</option>
                ))
              }
            </select>
          </Col>
        </Row>
        <Row style={{padding: '0.5em 0 0 0'}}>
          <Col>
            Từ: <input
              className='monitor-input-time-active'
              style={{ textAlign: 'center' }}
              type="time"
              id="fname"
              name="fname"
              value={fromTimer}
              onChange={(event) => setFromTimer(event.target.value)}
            // disabled={true}
            />
          </Col>
          <Col xs={1}><BiRightArrowAlt size={25} /></Col>
          <Col>
            Đến: <input
              className='monitor-input-time-active'
              style={{ textAlign: 'center' }}
              type="time"
              id="fname"
              name="fname"
              value={toTimer}
              onChange={(event) => setToTimer(event.target.value)}
            // disabled={true}
            />
          </Col>
        </Row>
        <Row style={{padding: '1em 1em 0 1em'}}>
          <Form style={{
            width: '100%'
          }}>
            <Form.Group as={Row}>
              <Col xs="5">
                <p style={{
                  fontSize: '18px',
                }}>Ngõ ra sử dụng:</p>
              </Col>
              <Col xs="2" style={{padding: 0, margin: 0}}>
                <input type="checkbox" checked={value1} onChange={(e) => setValue1(e.target.checked)} /> 1
              </Col>
              <Col xs="2">
                <input type="checkbox" checked={value2} onChange={(e) => setValue2(e.target.checked)} /> 2
              </Col>
              <Col xs="2">
                <input type="checkbox" checked={value3} onChange={(e) => setValue3(e.target.checked)} /> 3
              </Col>
            </Form.Group>
          </Form>
        </Row>
        <Row>
          <Col style={{ margin: 'auto'}}>
            {/* <Button onClick={() => this.onSubmitActiveTime()}> */}
            <Button onClick={() => {
              updateSchedule(stationId, activeSeason, fromTimeSeason, toTimeSeason, index, fromTimer, toTimer, value1, value2, value3);
            }}>
              Thiết lập
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )

}

export default SeasonSetup;