/* eslint-disable */
import React from "react";
import {w3cwebsocket as W3CWebSocket} from "websocket";
import * as ProtoCreator from './ProtoCreator';

import LoginPage from "./LoginPage/LoginPage";
import {DashboardPage, SIGN_SETTING_DEFAULT, STATION_VALUE_DEFAULT} from "./DashboardPage/DashboardPage";
import MapPage from './MapPage/MapPage';
import {ManagementPage} from "./ManagementPage/ManagementPage";
import {createNewAccountUserRequest} from "./ProtoCreator";
import { save } from 'save-file';
import { config } from "./Config";
import {convertScheduleModel} from "./DashboardPage/Schedule";
import {SCHEDULE_FORM} from "./DashboardPage/ScheduleSampleData";
import {forEach} from "react-bootstrap/ElementChildren";
import {AuthService} from "../services/AuthService";
import {StationService} from "../services/StationService";
import {ScheduleService} from "../services/ScheduleService";
import {UserService} from "../services/UserService";
import {DeviceService} from "../services/DeviceService";
import Cookies from 'js-cookie';

let MainProto = require('./protobuf/main_proto_pb');

// const this.state.deviceId = '11EFBFBD3B65EFBFBDEFBFBD08EFBFBD';

// const DEV_MODE = false;
// const DEV_MODE = true;

// const HOST_WS = DEV_MODE ? 'ws://127.0.0.1:8443/websocket' : 'wss://dev.hue-traffic.sitech.com.vn/websocket';
// const HOST_HTTP = DEV_MODE ? 'http://127.0.0.1:8443' : 'https://dev.hue-traffic.sitech.com.vn';
// const HOST_URL = 'dev.hue-traffic.sitech.com.vn';

// const HOST_WS = 'wssHOST_URL

class AppMain extends React.Component {
  constructor(props) {
    super(props);
    this.userId = this.props.userId || null;
    this.stationId = this.props.stationId || null;
    this.state = {
      isLogged: false,
      stationState: {...STATION_VALUE_DEFAULT},
      stationList: [
        {
          id: '123',
          deviceId: '',
          name: 'Ngã tư Thủ Đức',
          lat: 16.46969985961914,
          log: 107.60099792480469,
          isOn: false,
          isOnline: false,
          activeBegin: '--:--',
          activeEnd: '--:--',
        },
        {
          id: '123',
          deviceId: '',
          name: 'Ngã tư Võ Thị Sáu',
          lat: 16.46969985961914,
          log: 107.60099792480469,
          isOn: false,
          isOnline: false,
          activeBegin: '--:--',
          activeEnd: '--:--',
        }
      ],
      deviceId: null,
      user: "Trần Thanh Lộc",
      addStationResult: {
        stationName: null,
        numPhase: null,
        status: null,
        cpuType: null,
        lat: null,
        log: null
      },
      originLat: null,
      originLong: null,
      rootUserId: null,
      isManagement: false,
      accountList: [],
      role: 1,
      logContent: "",
      mapKey: "",
      latestApp: 'Unknown',
      email: '',
      phone: '',
      diaryLog: '',
      schedule: {...SCHEDULE_FORM},
    };

    this.ws = null;
    this.projectId = null;
    this.accessToken = null;
    this.tm = null;

    this.originLat = 0;
    this.originLong = 0;

    this.onSubmitLoginForm = this.onSubmitLoginForm.bind(this);
    this.onControlLane = this.onControlLane.bind(this);
    this.onSelectStation = this.onSelectStation.bind(this);
    this.callbackClose = this.callbackClose.bind(this);
    this.onSetActiveTime = this.onSetActiveTime.bind(this);
    this.onClickChangeSchedule = this.onClickChangeSchedule.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onSyncTime = this.onSyncTime.bind(this);
    this.onCheckDevice = this.onCheckDevice.bind(this);
    this.onAddStation = this.onAddStation.bind(this);
    this.onGetCoordinate = this.onGetCoordinate.bind(this);
    this.submitSignSetting = this.submitSignSetting.bind(this);
    this.onChangeManagementMode = this.onChangeManagementMode.bind(this);
    this.onDeleteAccount = this.onDeleteAccount.bind(this);
    this.onCreateNewAccount = this.onCreateNewAccount.bind(this);
    this.uploadFirmware = this.uploadFirmware.bind(this);
    this.getLatestFirmwareVersion = this.getLatestFirmwareVersion.bind(this);
    this.getLog = this.getLog.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.deleteStation = this.deleteStation.bind(this);
    this.onUpdateApp = this.onUpdateApp.bind(this);
    this.changeNumberCard = this.changeNumberCard.bind(this);
    this.updateErrorStatus = this.updateErrorStatus.bind(this);
    this.changeAlertInfo = this.changeAlertInfo.bind(this);
    this.submitDiary = this.submitDiary.bind(this);
    this.addAccessory = this.addAccessory.bind(this);
    this.deleteAccessory = this.deleteAccessory.bind(this);
    this.onGetSchedule = this.onGetSchedule.bind(this);
    this.checkIfConnected = this.checkIfConnected.bind(this);
    // this.ping = this.ping.bind(this);


  }

// single websocket instance for the own application and constantly trying to reconnect.

  componentDidMount() {

    Cookies.set('token', 'hello');
    console.log('t4est token: ' + Cookies.get('token'))
    console.log("mode " + process.env.REACT_APP_MODE + ", " + process.env.NODE_ENV)
    this.setState(state => state.isLogged = false);
    this.connect();
  }

  notify(message) {
    if (process.env.REACT_APP_MODE !== 'web') {
      window.api.send('asynchronous-message', 'dialog', null, null, message);
    } else {
      alert(message);
    }
  }

  async getStationList() {
    // this.ws.send(ProtoCreator.CreateGetStationListUserRequest(projectId));
    const stationService = new StationService();
    const data = await stationService.getStations();
    console.log(`stations: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      alert(data.message);
      return;
    }
    if (data && data.data && data.data.stations) {
      let stations = data.data.stations;
      this.setState(state => {
        state.stationList = [];
        stations.forEach(station => {
          console.log("station: " + station.stationName + ", hardwareId: " + station.hardwareId)
          state.stationList.push({
            id: station.id,
            deviceId: station.deviceId,
            name: station.stationName,
            lat: station.stationLat,
            log: station.stationLong,
            hardwareId: station.hardwareId,
            firmware: station.version,
            createDate: station.createDate,
            isOn: false,
            isOnline: station.online,
            activeBegin: "00:00",
            activeEnd: "00:00"
          })
        });
        return state;
      });
    }
  }

  async getStation(stationId) {
    // this.ws.send(ProtoCreator.CreateGetStationUserRequest(stationId));
    const stationService = new StationService();
    const data = await stationService.getStationById(stationId);
    console.log(`station: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      alert(data.message);
      return;
    }
    if (data && data.data) {
      let station = data.data;
      if (station === undefined) {
        console.log("station was null");
        return;
      }
      
      this.setState(state => {
        state.stationState = {...STATION_VALUE_DEFAULT};
        state.deviceId = station.deviceId;
        state.stationId = stationId;
        state.stationState.stationId = station.id
        state.stationState.stationInfo.stationName = station.stationName;
        state.stationState.stationInfo.type = station.cpuType;
        state.stationState.stationInfo.hardwareId = station.hardwareId;
        state.stationState.stationInfo.numPhase = 0;
        state.stationState.stationInfo.nCard = 0;
        state.stationState.control.onOff1 = station.power?.on1;
        state.stationState.control.onOff2 = station.power?.on2;
        state.stationState.control.onOff3 = station.power?.on3;
        state.stationState.control.enable1 = station.power?.enable1;
        state.stationState.control.enable2 = station.power?.enable2;
        state.stationState.control.enable3 = station.power?.enable3;
        state.stationState.control.dim = station.power?.dim;
        state.stationState.mode = station.power?.mode;

        state.stationState.meterData.i1 = station.meterData?.i1;
        state.stationState.meterData.i2 = station.meterData?.i2;
        state.stationState.meterData.i3 = station.meterData?.i3;

        state.stationState.meterData.u1 = station.meterData?.v1;
        state.stationState.meterData.u1 = station.meterData?.v1;
        state.stationState.meterData.u1 = station.meterData?.v1;

        state.stationState.meterData.p1 = station.meterData?.p1;
        state.stationState.meterData.p1 = station.meterData?.p1;
        state.stationState.meterData.p1 = station.meterData?.p1;

        state.stationState.meterData.a1 = station.meterData?.a1;
        state.stationState.meterData.a1 = station.meterData?.a1;
        state.stationState.meterData.a1 = station.meterData?.a1;
        state.stationState.isOnline = station.online;

        return state;
      });
      this.getDashboard(stationId);
      this.getSchdule(stationId);
      // this.requireStream(stationId, true);
      // this.ws.send(ProtoCreator.CreateRequireStreamUserRequest(this.state.deviceId, true));
    }
  }

  async getDashboard(stationId) {
    const stationService = new StationService();
    const data = await stationService.getStatistic(stationId);
    console.log(`statistic: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      return;
    }
    if (data && data.data) {
      this.setState(state => {
        state.stationState.chartData = {
          label: [],
          value: []
        }
        data.data.powers.forEach(item => {
          state.stationState.chartData.label.push(item.date);
          state.stationState.chartData.value.push(item.power);
        })
        return state;
      });
    }
  }

  async getSchdule(stationId) {
    const scheduleService = new ScheduleService();
    const data = await scheduleService.getScheduleById(stationId);
    console.log(`station: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      console.log(`Get schedule error: ${data.message}`);
      return;
    }
    let schedule = data.data;
    console.log(`schedule: ${JSON.stringify(schedule)}`)
    this.setState(state => {
      state.stationState.schedule.activeSeason = schedule?.useSunshine;
      state.stationState.schedule.seasonFrom = schedule.sunshineActive?.start;
      state.stationState.schedule.seasonTo = schedule.sunshineActive?.end;
      state.stationState.schedule.timers = [];
      if (schedule.timerActive) {
        schedule.timerActive.forEach(i => {
          state.stationState.schedule.timers.push({
            channel1: i.channel1,
            channel2: i.channel2,
            channel3: i.channel3,
            end: i.end,
            start: i.start
          })
        })
      }
      return state;
    });
  }

  async onCheckDevice(hardwareId) {
    // this.ws.send(ProtoCreator.createGetDeviceUserRequest(hardwareId));
    const deviceService = new DeviceService();
    const data = await deviceService.checkDevice(hardwareId);
    console.log(`check device: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      this.setState(state => state.addStationResult = {
        status: 'Không tìm thấy',
        numPhase: null,
        stationName: null,
        cpuType: null,
      });
      return;
    }
    if (data && data.data) {
      let response = data.data;
      console.log("lat: " + response.locationLat + ", long: " + response.locationLong);
      this.setState(state => {
        state.addStationResult = {
          stationName: response.assignedStationName === '' ? null : response.assignedStationName,
          numPhase: 2,
          status: response.online ?
            'Đang kết nối' : 'Mất kết nối',
          cpuType: response.cpuType,
          lat: response.locationLat,
          log: response.locationLong
        }
        state.originLat = response.locationLat;
        state.originLong = response.locationLong;
        return state;
      });
    }
  }

  timeout = 250; // Initial timeout duration as a class variable

  getTimeString(hour, minute) {
    let sHour = hour < 10 ? '0' + hour : '' + hour;
    let sMinute = minute < 10 ? '0' + minute : '' + minute;
    return `${sHour}:${sMinute}`;
  }

  getColorState(trafficSignState) {
    switch (trafficSignState) {
      case proto.TrafficLightState.TRAFFIC_LIGHT_STATE_GREEN:
        return 'green';
      case proto.TrafficLightState.TRAFFIC_LIGHT_STATE_YELLOW:
        return 'yellow';
      case proto.TrafficLightState.TRAFFIC_LIGHT_STATE_RED:
        return 'red';
    }
    return 'none';
  }

  ping = () => {
    if (!this.ws && this.ws.readyState !== WebSocket.CLOSED) this.ws.send('ping');
  }

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = () => {
    this.ws = new WebSocket(config.HOST_WS);
    this.ws.binaryType = "arraybuffer";
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    this.ws.onopen = () => {
      this.setState(state => state.isLogged = false);
      // this.setState(state => state.stationState.isOnline = true);

      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      if (this.tm != null) {
        clearTimeout(this.tm);
        this.tm = null;
      }
      this.tm = setTimeout(this.ping, 10000);
      if (this.userId !== null) {
        this.ws.send(
          ProtoCreator.CreateLoginMessage(
            localStorage.setItem('token'),
            this.stationId
          )
        );
      }
    };

    // websocket onclose event listener
    this.ws.onclose = e => {
      if (process.env.REACT_APP_MODE !== 'web' && this.props.userId !== null) {
        window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập không khả dụng!");
        window.close();
      }
      this.setState(state => state.stationState.isOnline = false);
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    this.ws.onerror = err => {
      this.setState(state => state.isLogged = false);
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      this.ws.close();
    };

    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      let mainMessage = proto.MainMessage.deserializeBinary(evt.data);
      console.log(`has a mainMessage : ${mainMessage}`);
      if (mainMessage?.hasUsermessage()) {
        let userMessage = mainMessage.getUsermessage();
        if (userMessage.hasAuthusermessage()) {
          if (userMessage.getAuthusermessage().hasUserloginresponse()) {
            console.log("has UserLoginResponse");
            let userLoginResponse = userMessage.getAuthusermessage().getUserloginresponse();
            if (userLoginResponse.getStatuscode().getCode() === 0) {
              console.log("Đăng nhập thành công, projectId " + userLoginResponse.getProjectid());
              this.projectId = userLoginResponse.getProjectid();
              this.setState(state => {
                state.isLogged = true;
                state.rootUserId = userLoginResponse.getUserid();
                state.role = userLoginResponse.getRole();
                state.mapKey = userLoginResponse.getMapkey();
                state.projectId = userLoginResponse.getProjectid();
                state.email = userLoginResponse.getEmail();
                state.phone = userLoginResponse.getPhone();
                return state;
              });
              // this.getStation("11EB8B196396BAA8B39934E12D8889AC");
            } else {
              // window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập bị từ chối!");
              this.notify("Truy cập bị từ chối!");
            }
          }
          // else if (userMessage.getAuthusermessage().hasGetaccesstokenuserresponse()) {
          //   console.log("hasGetaccesstokenuserresponse");
          //   let token = userMessage.getAuthusermessage().getGetaccesstokenuserresponse().getAccesstoken();
          //   console.log("token %s", token);
          //   this.accessToken = token;
          // }
        } else if (userMessage.hasDeviceonchangeusermessage()) {
          let deviceOnChange = userMessage.getDeviceonchangeusermessage();
          if (deviceOnChange.hasDevicestateonchange()) {
            let stateOnChange = deviceOnChange.getDevicestateonchange().getDevicestate();
            console.log(`power state message: ${stateOnChange}`)
            this.setState(state => {
              state.stationState.control.onOff1 = stateOnChange.getPowerstate().getOn1();
              state.stationState.control.onOff2 = stateOnChange.getPowerstate().getOn2();
              state.stationState.control.onOff3 = stateOnChange.getPowerstate().getOn3();
              state.stationState.control.dim = stateOnChange.getDim();
              state.stationState.control.enable1 = stateOnChange.getPowerstate().getEnableoutput1();
              state.stationState.control.enable2 = stateOnChange.getPowerstate().getEnableoutput2();
              state.stationState.control.enable3 = stateOnChange.getPowerstate().getEnableoutput3();
              state.stationState.mode = stateOnChange.getPowerstate().getMode();
              return state;
            })
          } else if (deviceOnChange.hasMeterdataupdateusermessage()) {
            let updateState = deviceOnChange.getMeterdataupdateusermessage();
            let meterData = updateState.getMeterdata();
            console.log(`meterData message: ${updateState}`)
            console.log("Update meter %s: %s - %d", updateState.getDeviceid(), meterData?.getP1());
            this.setState(state => {
              state.stationState.meterData.a1 = Math.abs(meterData.getA1());
              state.stationState.meterData.a2 = Math.abs(meterData.getA2());
              state.stationState.meterData.a3 = Math.abs(meterData.getA3());

              state.stationState.meterData.i1 = meterData.getI1();
              state.stationState.meterData.i2 = meterData.getI2();
              state.stationState.meterData.i3 = meterData.getI3();

              state.stationState.meterData.u1 = meterData.getV1();
              state.stationState.meterData.u2 = meterData.getV2();
              state.stationState.meterData.u3 = meterData.getV3();

              state.stationState.meterData.p1 = meterData.getP1();
              state.stationState.meterData.p2 = meterData.getP2();
              state.stationState.meterData.p3 = meterData.getP3();
              return state;
            })
          } else if (deviceOnChange.hasUpdatedeviceconnectionstate()) {
            let connectionState = deviceOnChange.getUpdatedeviceconnectionstate();
            console.log(`connectionState message: ${connectionState}`)
            if (connectionState.getConnectionstate() === proto.ConnectionState.CONNECTION_ONLINE) {
              console.log("device is online");
              this.setState(state => state.stationState.isOnline = true);
              this.requireStream(this.state.deviceId, true);
            } else {
              console.log("device is offline");
              this.setState(state => state.stationState.isOnline = false);
            }
          } else if (deviceOnChange.hasLogupdate()) {
            let logMessage = deviceOnChange.getLogupdate();
            console.log(`log message: ${logMessage}`)
          } else if (deviceOnChange.hasUpdateschedulecompletedusermessage()) {
            let schedule = deviceOnChange.getUpdateschedulecompletedusermessage()
            console.log(`schedule update: ${schedule}`)
            this.setState(state => {
              let scheduleData = schedule.getSchedule();
              state.stationState.schedule.activeSeason = scheduleData.getUsesunshine();
              let h = scheduleData.getSunshineactive()?.getActivelamp()?.getBegin()?.getHour();
              let m = scheduleData.getSunshineactive()?.getActivelamp()?.getBegin()?.getMinute();
              let start = this.getTimeString(h, m);
              state.stationState.schedule.seasonFrom = start;
              h = scheduleData.getSunshineactive()?.getActivelamp()?.getEnd()?.getHour();
              m = scheduleData.getSunshineactive()?.getActivelamp()?.getEnd()?.getMinute();
              start = this.getTimeString(h, m);
              state.stationState.schedule.seasonTo = start;
              state.stationState.schedule.timers = [];
              if (scheduleData.getTimeractiveList().length > 0) {
                scheduleData.getTimeractiveList().forEach(i => {
                  let time = i.getActivelamp();
                  let h = time.getBegin().getHour();
                  let m = time.getBegin().getMinute();
                  let start = this.getTimeString(h, m);
                  h = time.getEnd().getHour();
                  m = time.getEnd().getMinute();
                  let end = this.getTimeString(h, m);
                  state.stationState.schedule.timers.push({
                    channel1: i.getChannel1(),
                    channel2: i.getChannel2(),
                    channel3: i.getChannel3(),
                    end: end,
                    start: start
                  })
                })
              }
              return state;
            })
          }
        }
      }
    }
  };

  componentWillUnmount() {
    this.ws.close();
  }

  async onSubmitLoginForm(userName, password, name) {
    this.setState(state => state.user = name + " (" + userName + ")");
    // window.api.send('asynchronous-message', 'dialog', null, null, "hello");
    /*
    if (this.ws) {
      this.ws.send(ProtoCreator.CreateLoginMessage(userName, password, null, null, process.env.REACT_APP_MODE === 'web'));
    }

     */

    const authService = new AuthService();
    const data = await authService.login({ username: userName, password: password });
    console.log(`response: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      alert(data.message);
      return;
    }
    if (data.data?.token) {
      localStorage.setItem('token', data.data.token)
      localStorage.setItem('refreshToken', data.data.refreshToken)
      if (this.ws) {
        this.ws.send(ProtoCreator.CreateLoginMessage(data.data.token, null));
      }
    }
    const userService = new UserService();
    const info = await userService.getUserInfo();
    if (info.code === 0) {
      console.log(`User data: ${JSON.stringify(info.data)}`);
      this.setState(state => {
        state.isLogged = true;
        state.rootUserId = info.data?.id;
        state.role = info.data?.role;
        // state.mapKey = userLoginResponse.getMapkey();
        state.projectId = info.data?.projectId;
        state.email = info.data?.email;
        state.phone = info.data?.phone;
        return state;
      });
    }
    // if (this.props.stationId !== null) {
    //   this.getStation(this.props.stationId);
    // } else {
    this.getStationList();
    // }

    // alert(userName + " " + password);
  }

  onSetActiveTime(beginHour, beginMinute, endHour, endMinute, beginHourE, beginMinuteE, endHourE, endMinuteE) {
    this.ws.send(ProtoCreator.SetActiveTimeUserRequest(this.state.deviceId, beginHour, beginMinute, endHour, endMinute, beginHourE, beginMinuteE, endHourE, endMinuteE));
    /*this.setState((state) => {
      state.stationState.activeTimeBegin = beginHour + ":" + beginMinute;
      state.stationState.activeTimeEnd = endHour + ":" + endMinute;
      return state;
    });*/
  }

  async onControlLane(on1, on2, on3) {
    let stationId = this.state.stationId;
    console.log(`stationId: ${stationId}`);
    const stationService = new StationService();
    const data = await stationService.powerControl(stationId, on1, on2, on3);
    console.log(`station: ${JSON.stringify(data)}`)
    if (data && data.code !== 0) {
      alert(data.message);
      return;
    }
    // let control = ProtoCreator.CreateControlLaneUserMessage(deviceId, isPowerOn, index);
    // this.ws.send(control);
    /*this.setState((state) => {
      state.stationState.control.onOff = isPowerOn;
      return state;
    });*/

  }

  onSelectStation(stationId) {
    console.log("selected stationId " + stationId);
    this.getStation(stationId);
  }

  callbackClose() {
    console.log("callbackClose, close streaming");
    this.setState(state => {
      state.deviceId = null;
      state.stationState = {...STATION_VALUE_DEFAULT};
      return state;
    });
    // this.ws.send(ProtoCreator.CreateRequireStreamUserRequest(this.state.deviceId, false));
    this.getStationList();
  }

  onClickChangeSchedule(greenTime, yellowTime, clearanceTime) {
    this.ws.send(ProtoCreator.CreateSetCycleConfigUserRequest(
      this.state.deviceId, 2, yellowTime, clearanceTime, greenTime)
    );
  }

  onLogout() {
    this.setState(state => state.isLogged = false);
    this.ws.close();
    this.check();
  }

  onSyncTime() {
    this.ws.send(ProtoCreator.createSyncTimeUserRequest(this.state.deviceId));
  }

  async onAddStation(stationName, hardwareId, stationLat, stationLong) {
    // this.ws.send(ProtoCreator.createAddStationUserRequest(stationName, hardwareId, stationLat, stationLong));
    const stationService = new StationService();
    const data = await stationService.addStation({hardwareId: hardwareId, stationName: stationName});
    console.log(`add station: ${JSON.stringify(data)}`)
    alert(data.message);
    if (data && data.code !== 0) {
      return;
    }
    this.getStationList();
  }

  async requireStream(stationId, enable) {
    const stationService = new StationService();
    const data = enable ? await stationService.openStream(stationId) : await stationService.closeStream(stationId);
    console.log(`require stream: ${JSON.stringify(data)}`)
  }

  onGetCoordinate(hardwareId) {
    this.ws.send(ProtoCreator.createGetCoordinateUserRequest(hardwareId));
  }

  submitSignSetting(stationId, setting, backgroundSrc) {
    this.ws.send(ProtoCreator.createSetSignSettingUserRequest(stationId, setting, backgroundSrc));
  }

  onChangeManagementMode(isActive) {
    this.setState(state => state.isManagement = isActive);
    if (isActive) {
      this.onGetAccounts();
      this.getLatestFirmwareVersion();
      // this.getStationList(this.projectId);
      this.ws.send(ProtoCreator.getAccessKeyUserRequest());
    }
    this.getStationList();
  }

  onGetAccounts() {
    this.ws.send(ProtoCreator.getAccountsUserRequest(this.projectId));
  }

  onDeleteAccount(userId) {
    this.ws.send(ProtoCreator.deleteAccountUserRequest(userId));
  }

  onCreateNewAccount(userName, password, role) {
    this.ws.send(ProtoCreator.createNewAccountUserRequest(userName, password, role));
  }

  uploadFirmware(firmwareVersion, file) {
    if (this.accessToken === null) {
      this.notify("Mã truy cập không hợp lệ");
    }
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fwVer', firmwareVersion);
    fetch(
      config.HOST_HTTP + "/api/v1/ota/upload",
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.accessToken
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log("response " + response.status);
        if (response.status === 201) {
          this.notify("Upload phiên bản " + firmwareVersion + " thành công")
        }
        // response.status;
      })
      .finally(() => {
        this.getLatestFirmwareVersion();
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    ;
  }

  onUpdateApp() {
    if (process.env.REACT_APP_MODE === 'web') {
      this.notify("Tính năng không hỗ trợ!")
      return;
    }

    if (this.accessToken === null) {
      this.notify("Mã truy cập không hợp lệ");
    }

    fetch(
      config.HOST_HTTP + "/api/v1/ota/app-check?version=" + config.version + "&os=" + config.os + "&projectId=" + this.projectId,
      {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.accessToken
        }
      }
    )
      .then((response) => {
        if (response.status === 200) {
          let fileName = response.headers.has('content-disposition') ? response.headers.get('content-disposition').split('filename=')[1] : 'app.exe';
          console.log("response " + fileName);
          response.blob().then(blob => {
            save(blob, fileName).then(() => {
              alert("[Cảnh báo]: Vui lòng cài đặt chương trình mới!");
              window.close();
            });
            // window.close();
          });
        }
        // response.blob();
        // response.status;
      })
      .finally(() => {
        // this.getLatestFirmwareVersion();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  getLatestFirmwareVersion() {
    this.ws.send(ProtoCreator.getLatestFirmwareUserRequest());
  }

  getLog(date) {
    this.ws.send(ProtoCreator.getLogUserRequest(date));
  }

  changePassword(oldPassword, newPassword) {
    this.ws.send(ProtoCreator.changePasswordUserRequest(oldPassword, newPassword));
  }

  deleteStation(stationId) {
    this.ws.send(ProtoCreator.deleteStationUserRequest(stationId));
  }

  changeNumberCard(stationId, nCard) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.editNumberCard(stationId, nCard));
  }

  updateErrorStatus(stationId, status) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.updateErrorStatus(stationId, status));
  }

  changeAlertInfo(email, phone) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.updateAlertInfo(email, phone));
  }

  submitDiary(stationId, message) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.updateDiaryUserRequest(stationId, message));
    this.ws.send(ProtoCreator.getAccessoriesUserRequest(stationId));
  }

  addAccessory(stationId, name, model, madeBy, position, installedPosition, installedDate, state) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.insertAccessoryUserRequest(stationId, name, model, madeBy, position, installedPosition, installedDate, state));
  }

  deleteAccessory(stationId, accessoryId) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.deleteAccessoryUserRequest(stationId, accessoryId));
  }

  onGetSchedule(stationId) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.getScheduleUserRequest(stationId));
  }

  onUpdateSchedule(stationId, schedule) {
    if (!this.checkIfConnected()) {
      return;
    }
    this.ws.send(ProtoCreator.updateSchedule(stationId, schedule))
  }

  checkIfConnected() {
    return this.ws !== undefined && this.ws.readyState !== WebSocket.CLOSED;
  }

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  render() {
    let show = <div/>
    let managementPage = <ManagementPage
      onExit={() => this.onChangeManagementMode(false)}
      // onGetAccounts={this.onGetAccounts}
      accountList={this.state.accountList}
      onCreateNewAccount={this.onCreateNewAccount}
      onDeleteAccount={this.onDeleteAccount}
      onClose={() => this.onChangeManagementMode(false)}
      uploadFirmware={this.uploadFirmware}
      changeAlertInfo={this.changeAlertInfo}
      latestFirmware={this.state.latestFirmware}
      latestApp={this.state.latestApp}
      stationList={this.state.stationList}
      getLog={this.getLog}
      logContent={this.state.logContent}
      role={this.state.role}
      changePassword={this.changePassword}
      deleteStation={this.deleteStation}
      projectId={this.state.projectId}
      onUpdateApp={this.onUpdateApp}
      email={this.state.email}
      phone={this.state.phone}
    />
    let mapPage = <MapPage
      stationList={this.state.stationList}
      onSelectStation={this.onSelectStation}
      onLogout={this.onLogout}
      user={this.state.user}
      onCheckDevice={this.onCheckDevice}
      addStationResult={this.state.addStationResult}
      onAddStation={this.onAddStation}
      onGetCoordinate={this.onGetCoordinate}
      originLat={this.state.originLat}
      originLong={this.state.originLong}
      rootUserId={this.state.rootUserId}
      onManagement={() => this.onChangeManagementMode(true)}
      role={this.state.role}
      mapKey={this.state.mapKey}
      onControlLane={this.onControlLane}
    />
    let dashboardPage = <DashboardPage
      value={this.state.stationState}
      onControlLane={this.onControlLane}
      callbackClose={this.callbackClose}
      onChangeSchedule={this.onClickChangeSchedule}
      onSetActiveTime={this.onSetActiveTime}
      onSyncTime={this.onSyncTime}
      user={this.props.user || this.state.user}
      submitSignSetting={this.submitSignSetting}
      role={this.state.role}
      diaryLog={this.state.diaryLog}
      changeNumberCard={this.changeNumberCard}
      updateErrorStatus={this.updateErrorStatus}
      submitDiary={this.submitDiary}
      addAccessory={this.addAccessory}
      deleteAccessory={this.deleteAccessory}
      onGetSchedule={this.onGetSchedule}
      schedule={this.state.schedule}
      onUpdateSchedule={this.onUpdateSchedule.bind(this)}
    />;
    let loginPage = <LoginPage onSubmitLoginForm={this.onSubmitLoginForm}/>;
    if (this.state.isLogged) {
      if (this.state.isManagement === true) {
        show = managementPage;
      } else if (this.state.deviceId === null && this.props.userId === null) {
        show = mapPage
      } else {
        show = dashboardPage
      }
    } else {
        show = this.props.userId === null && loginPage;
    }
    return (
      <div>
        {show}
        {/*{loginPage}*/}
        {/*{mapPage}*/}
        {/* {dashboardPage} */}
        {/*{managementPage}*/}
      </div>
    )
  }
}

export default AppMain;